// topnav

.topnav {
  overflow: hidden;
  // background-color: #333;
  // position: fixed;
}

.topnav .myLinks {
  display: none;
}

.topnav a {
  color: white;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  display: block;
  text-align: left;
}

.hamburger:hover {
  color: black;
  // background-color: white;
}
