// variables

$background-color: #524743;
$font-size: 22px;
$hero-font-size: 210px;

$font-family: "Courier New", Courier, monospace;
$hero-font-family: Arial, Helvetica, sans-serif;
$font-color: white;

// media query breakpoints

$media-max-width-1: 1164px;
$media-max-width-2: 768px;
$media-max-width-3: 699px;
$media-max-width-4: 492px;
$media-max-width-5: 410px;
$media-max-width-6: 330px;
$media-max-width-7: 230px;

$media-max-width-galaxy-fold: 280px;
