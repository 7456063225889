// header section

@import "../components/nav.scss";

header {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin: 40px 2.5% 0;
  width: 95%;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(3px);

  .homeworld-icon {
    margin-left: 10px;
    // width: 200px;
    // height: auto;
  }

  .hamburger {
    padding-right: 5px;
  }

  @media screen and (min-width: 769px) {
    .hamburger {
      display: none;
    }
    .social-media-icons {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  header {
    padding-bottom: 15px;
  }
}
@media screen and (max-width: 600px) {
  header {
    margin-top: 25px;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 410px) {
  header {
    margin-top: 20px;
    padding-bottom: 10px;
  }
}

// galaxy fold

@media screen and (max-width: 280px) {
  header {
    margin-top: 15px;
    padding-bottom: 10px;
    font-size: 17px;
  }
}
